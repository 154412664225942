import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/home';
import LostCity from '../pages/lostCity';
import ItemDetails from '../pages/itemDetails';
import Flight from '../pages/flight';
import Railway from '../pages/railway';

const router = createBrowserRouter([
  { 
    path: '/',
    element: <App />
  },
  { 
    path: '/home',
    element: <Home />
  },
  { 
    path: '/lostCity',
    element: <LostCity />
  },
  { 
    path: '/itemDetails',
    element: <ItemDetails />
  },
  { 
    path: '/flight',
    element: <Flight />
  },
  { 
    path: '/railway',
    element: <Railway />
  }
]);

export default router;