import React, { useEffect, useState } from 'react';
import './home.css';
import './media.css';
import { Modal, Button, ConfigProvider } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
const HomePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let chatMsg = 'https://wa.me/97336235523?text=hello%20I%20lost%20something.%20Flight%20number%3A%20Airport%3A%20Lost%20item%3A%20Lost%20time%3A%20';

  useEffect(() => {
    const button = document.querySelector('.search_btn') as HTMLElement;
    const interval = setInterval(() => {
      button.classList.add('shaking');
      setTimeout(() => {
        button.classList.remove('shaking');
      }, 500); // 500ms 是 shake 动画的持续时间
    }, 3000); // 每 3 秒触发一次抖动

    return () => clearInterval(interval); // 清除定时器
  }, []);

  return (
    <div className='adv_container'>
      <div className='header'>
        <div className={'header_cover'}>
          <img src={require('./headerLogo.png')} alt="display" />
        </div>
      </div>
      <div className='six_characteristics'>
        <div className='slogan_title'>Lost&Found call SoonFound!</div>
        <div className='title_line'></div>
        <div className='six_characteristics_title'>Supports</div>
        <div className='first_three_characteristics'>
          <div className='characteristic_container'>
            <div className='characteristic_title'>Fast Online Request</div>
            <div className='characteristic_line'></div>
            <div className='characteristic_content'>No registration</div>
            <div className='characteristic_content'>contact in Whatsapp/Telegram</div>
            <div className='characteristic_content'>Support multiple languages</div>
          </div>
          <div className='characteristic_container'>
            <div className='characteristic_title'>Professional Service</div>
            <div className='characteristic_line'></div>
            <div className='characteristic_content'>Confirm lost details accurately</div>
            <div className='characteristic_content'>Find the right place</div>
            <div className='characteristic_content'>Ask the right person</div>
          </div>
          <div className='characteristic_container'>
            <div className='characteristic_title'>More Scenarios</div>
            <div className='characteristic_line'></div>
            <div className='characteristic_content'>Flights/Airports</div>
            <div className='characteristic_content'>Trains/Stations</div>
            <div className='characteristic_content'>Taxi/Subway/Bus</div>
          </div>
          <div className='characteristic_container'>
            <div className='characteristic_title'>Value-added Services</div>
            <div className='characteristic_line'></div>
            <div className='characteristic_content'>Urgently looking for / retrieve</div>
            <div className='characteristic_content'>Entrust global mailing (when find)</div>
            <div className='characteristic_content'>Reward offered for lost items</div>
          </div>
          <div className='characteristic_container'>
            <div className='characteristic_title'>Big Lost Items Data</div>
            <div className='characteristic_line'></div>
            <div className='characteristic_content'>Timely information source</div>
            <div className='characteristic_content'>Cycle system search</div>
            <div className='characteristic_content'>Connect multiple departments</div>
          </div>
          <div className='characteristic_container'>
            <div className='characteristic_title'>Around The Clock</div>
            <div className='characteristic_line'></div>
            <div className='characteristic_content'>24 hours / 7</div>
            <div className='characteristic_content'>Open on holidays</div>
            <div className='characteristic_content'>No time difference</div>
          </div>
        </div>
      </div>
      <div className='cases'>
        <div className='case_title'>Case</div>
        <div className='case_content'>
          <div className='first_cases'>
            <div className='case_box'>
              <div className='case_box_subtitle'>
                Found his passport at Beijing South Station
              </div>
              <div className='case_box_container'>
                <div className='case_box_profile'>
                  <div className='avatar'>
                    <span><img src={require('./sf-avator3.png')} alt="" /></span>
                  </div>
                  <div className='owner'>
                    <div className='owner_name'>
                      <span>Jack</span>
                    </div>
                    <div className='theme'>
                      <span>found on 04/06/2023</span>
                    </div>
                  </div>
                </div>
                <div className='speech_content'>
                  Thank you Soonfound for helping me find my passport! Losing it on a business trip is very troublesome.
                </div>
              </div>
            </div>
            <div className='case_box'>
              <div className='case_box_subtitle'>
                Found his iPad at Shanghai Pudong Airport
              </div>
              <div className='case_box_container'>
                <div className='case_box_profile'>
                  <div className='avatar'>
                    <span><img src={require('./sf-avator2.png')} alt="" /></span>
                  </div>
                  <div className='owner'>
                    <div className='owner_name'>
                      <span>Jimmy</span>
                    </div>
                    <div className='theme'>
                      <span>found on 07/10/2023</span>
                    </div>
                  </div>
                </div>
                <div className='speech_content'>
                  Soonfound is so awesome!!! He found my iPad in less than half an hour. It's amazing!
                </div>  
              </div>
            </div>
          </div>
          <div className='second_cases'>
            <div className='case_box'>
              <div className='case_box_subtitle'>
                Found her luggage in the trunk of the taxi
              </div>
              <div className='case_box_container'>
                <div className='case_box_profile'>
                  <div className='avatar'>
                    <span><img src={require('./sf-avator1.png')} alt="" /></span>
                  </div>
                  <div className='owner'>
                    <div className='owner_name'>
                      <span>Cara</span>
                    </div>
                    <div className='theme'>
                      <span>found on 17/12/2023</span>
                    </div>
                  </div>
                </div>
                <div className='speech_content'>
                  The Soonfound’s staff contacted me quickly after the order. It was incredibly fast… and soon found my backpack.
                </div>  
              </div>
              
            </div>
            <div className='case_box'>
              <div className='case_box_subtitle'>
                Found her suitcase at Guangzhou Baiyun Airport
              </div>
              <div className='case_box_container'>
                <div className='case_box_profile'>
                  <div className='avatar'>
                    <span><img src={require('./sf-avator4.png')} alt="" /></span>
                  </div>
                  <div className='owner'>
                    <div className='owner_name'>
                      <span>Andy</span>
                    </div>
                    <div className='theme'>
                      <span>found on 27/05/2024</span>
                    </div>
                  </div>
                </div>
                <div className='speech_content'>
                  Thank you for finding it and mailing it home! Honestly, if I can't get them back, I might be considered a fool.
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='serviceArea'>
        <div className='serviceArea_title'>Service Area</div>
        <div className='serviceArea_content'>
          <div className='serviceArea_box'>
            <div className='serviceArea_Img'>
              <img src={require('./daxingAirport.png')} alt="display" />
            </div>
            <div className='serviceArea_name'>
              Beijing Daxing
            </div>
            <div className='serviceArea_name'>
              International Airport
            </div>
          </div>
          <div className='serviceArea_box'>
            <div className='serviceArea_Img'>
              <img src={require('./pudongAirport.png')} alt="display" />
            </div>
            <div className='serviceArea_name'>
              Shanghai Pudong
            </div>
            <div className='serviceArea_name'>
              International Airport
            </div>
          </div>
          {/* <div className='serviceArea_box'>
            <div className='serviceArea_Img'>
              <img src={require('./shenzhenAirport.png')} alt="display" />
            </div>
            <div className='serviceArea_name'>
              Shenzhen International
            </div>
            <div className='serviceArea_name'>
              Airport
            </div>
          </div> */}
          <div className='serviceArea_box'>
            <div className='serviceArea_Img'>
              <img src={require('./guangzhouAirport.png')} alt="display" />
            </div>
            <div className='serviceArea_name'>
              Guangzhou Baiyun
            </div>
            <div className='serviceArea_name'>
              International Airport
            </div>
          </div>
          <div className='serviceArea_box'>
            <div className='serviceArea_Img'>
              <img src={require('./beijingnanStation.png')} alt="display" />
            </div>
            <div className='serviceArea_name'>
              Chengdu Tianfu
            </div>
            <div className='serviceArea_name'>
              International Airport
            </div>
          </div>
          {/* <div className='serviceArea_box'>
            <div className='serviceArea_Img'>
              <img src={require('./hongqiaoStation.png')} alt="display" />
            </div>
            <div className='serviceArea_name'>
              Shanghai Hongqiao
            </div>
            <div className='serviceArea_name'>
              Railway Station
            </div>
          </div> */}
        </div>
      </div>
      <div className='slogan'>
        <div className='slogan_content'>
          <div className='slogan_box'>
            <div className='slogan_box_title'>Supporting Major Stations</div>
            <div className='slogan_box_paragraph'>• Beijing South Railway Station</div>
            <div className='slogan_box_paragraph'>• Shanghai Hongqiao Railway Station</div>
            <div className='slogan_box_paragraph'>• Guangzhou Railway Station</div>
            <div className='slogan_box_paragraph'>• Shenzhen Railway Station</div>
          </div>
          <div className='slogan_box slogan_box2'>
            <div className='slogan_box_title'>Supporting Major Airports</div>
            <div className='slogan_box_paragraph'>• Beijing Daxing International Airport</div>
            <div className='slogan_box_paragraph'>• Shanghai Pudong International Airport</div>
            <div className='slogan_box_paragraph'>• Guangzhou Baiyun International Airport</div>
            <div className='slogan_box_paragraph'>• Shenzhen Bao'an International Airport</div>
          </div>
        </div>
      </div>
      <div className='introduction'>
        <div className='introduction_title'>About Us</div>
        <div className='introduction_content'>
          <div className='introduction_paragraph'>SoonFound is a company which offers the professional Lost and Found services for more than 10 years. SoonFound is willing to serve the lost owners around the world and is recommended by national newspaper and media.</div>
        </div>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorBgContainer: `#1aad17`,
              colorPrimary: 'white',
              colorPrimaryHover: 'white',
              colorPrimaryActive: 'white'
            },
          },
        }}
      >
        <Button className='search_btn shining_btn' href={chatMsg}>
          <WhatsAppOutlined className='whatsapp_icon'/>
          <span className='btn_content'>Chat on Whatsapp</span>
        </Button>
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: 25
            },
          },
        }}
      >
        <Modal title="Add Whatsapp" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
          <img width={'100%'} src={require("./whatsappQr.jpg")} alt="whatsapp display" />
        </Modal>
      </ConfigProvider>
    </div>
  )
};

export default HomePage;